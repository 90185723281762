import { render, staticRenderFns } from "./CommandsTool.vue?vue&type=template&id=c67447ae&scoped=true"
import script from "./CommandsTool.vue?vue&type=script&lang=js"
export * from "./CommandsTool.vue?vue&type=script&lang=js"
import style0 from "./CommandsTool.vue?vue&type=style&index=0&id=c67447ae&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c67447ae",
  null
  
)

export default component.exports